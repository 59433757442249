import React from 'react'
import { useTranslation } from 'react-i18next'

import Webapp from '../assets/svg/expertise/webapp.svg'
import Backend from '../assets/svg/expertise/backend.svg'
import Cloud from '../assets/svg/expertise/cloud.svg'
import Database from '../assets/svg/expertise/database.svg'
import Data from '../assets/svg/expertise/data.svg'

const Expertise = () => {
    const { t } = useTranslation('expertise')

    return (
        <div className="expertisePage" id="expertise">
            <h2 className="pageTitle">{t('header')}</h2>
            <hr />
            <div className="expertiseGrid">
                <div className="expertiseBox">
                    <span className="expertiseTitle">{t('webapp')}</span>
                    <Webapp className="webappIcon" />
                </div>
                <div className="expertiseBox">
                    <span className="expertiseTitle">{t('backend')}</span>
                    <Backend className="backendIcon" />
                </div>
                <div className="expertiseBox">
                    <span className="expertiseTitle">{t('cloud')}</span>
                    <Cloud className="cloudIcon" />
                </div>
                <div className="expertiseBox">
                    <span className="expertiseTitle">{t('database')}</span>
                    <Database className="databaseIcon" />
                </div>
                <div className="expertiseBox">
                    <span className="expertiseTitle">{t('data')}</span>
                    <Data className="dataIcon" />
                </div>
            </div>
        </div>
    )
}

export default Expertise
